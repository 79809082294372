import styled from 'styled-components';
import { fade } from '../../styles/animations';

interface IWrapperProps {
  background: (string | undefined)[];
  isArabesque?: boolean;
}

export const ConnectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ConnectBody = styled.div`
  width: 100%;
  height: 60%;
  background: #fff;
  animation: ${fade} 3s;
`;

export const LinkWrapper = styled.div<IWrapperProps>`
  background: linear-gradient(
    141.65deg,
    ${(props) => props.background[0] || `#fff`} 1.62%,
    ${(props) => props.background[1] || `#fff`} 104.12%
  );
  border-radius: ${(props) => (props.isArabesque && `2rem 0 0 0`) || `0`};
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0;
  display: grid;
  grid-template-rows: 10fr 1fr;
  grid-template-columns: 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
`;

export const LinkScroll = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* overflow-y: scroll; */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`;
