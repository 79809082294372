import styled from 'styled-components';

interface IButtonProps {
  isHighlighted?: boolean;
  background: string;
  color: string;
}

export const ButtonBody = styled.button<IButtonProps>`
  border: ${(props) =>
    (props.isHighlighted && `1px solid ${props.background}`) || `none`};
  width: 100%;
  background: transparent;
  padding: 0.5rem;
  a {
    width: 100%;
    font-size: 1rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    text-decoration: none;
    padding: 1rem 0;
    font-weight: ${(props) => (props.isHighlighted && `700`) || '400'};
  }
`;
