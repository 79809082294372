import React from 'react';

// Styles
import { LogoWrapper, Text, Image } from './styles';

interface IProps {
  img?: string;
  text?: string;
}

const Logo: React.FC<IProps> = (props) => {
  const { img, text } = props;

  return (
    <LogoWrapper>
      {img ? <Image src={img} /> : <div />}
      {text ? <Text>{text}</Text> : <div />}
    </LogoWrapper>
  );
};

export default Logo;
