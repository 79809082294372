import React from 'react';

// Components
import SocialMediaIcon from '../SocialMediaIcon';
import Logo from '../Logo';

// Types
import { ICompanyData } from '../../Types';

// Styles
import { HeaderContainer, HeaderContent, SocialMedia } from './styles';

interface IProps {
  company?: ICompanyData;
  background: (string | undefined)[];
  isArabesque?: boolean;
  img?: string;
  text?: string;
  headerColor?: string;
  matchColors?: boolean;
}

const Header: React.FC<IProps> = (props) => {
  const {
    company,
    background,
    isArabesque,
    img,
    headerColor,
    matchColors,
  } = props;

  return (
    <HeaderContainer
      background={background}
      style={{ height: `${matchColors ? '30%' : ''}` }}
    >
      <HeaderContent isArabesque={isArabesque} headerColor={headerColor}>
        <Logo
          img={img || undefined}
          // text={company?.name}
        />
        <SocialMedia>
          {company?.instagram ? (
            <SocialMediaIcon
              url={`https://instagram.com/${company.instagram}`}
              instagram
            />
          ) : (
            <div />
          )}
          {company?.facebook ? (
            <SocialMediaIcon
              url={`https://facebook.com/${company.facebook}`}
              facebook
            />
          ) : (
            <div />
          )}
          {company?.youtube ? (
            <SocialMediaIcon
              url={`https://youtube.com/${company.youtube}`}
              youtube
            />
          ) : (
            <div />
          )}
          {company?.twitter ? (
            <SocialMediaIcon
              url={`https://twitter.com/${company.twitter}`}
              twitter
            />
          ) : (
            <div />
          )}
        </SocialMedia>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
