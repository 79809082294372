import React, { FC, useState } from 'react';
import { Switch } from 'antd';
import Typed from 'react-typed';
import { FiArrowRight } from 'react-icons/fi';

import './styles.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import gif from '../../assets/landing-page/gifpremiere.gif';
import mobileMockup from '../../assets/landing-page/cellphone-mockup.png';
import macMockup from '../../assets/landing-page/mac-mockup.png';
import connect from '../../assets/landing-page/connect.png';
import line from '../../assets/landing-page/line-bg.svg';
import suitshare from '../../assets/landing-page/suiteshare.png';

import bananelaInsta from '../../assets/landing-page/bananella-insta.png';
import bananelaConnect from '../../assets/landing-page/bananella-connect.png';

// icons
import whatsappFilled from '../../assets/landing-page/icons/whatsapp.png';
import pinterestFilled from '../../assets/landing-page/icons/pinterest.png';
import tiktokFilled from '../../assets/landing-page/icons/tiktok.png';
import facebookFilled from '../../assets/landing-page/icons/facebook.png';
import instagramFilled from '../../assets/landing-page/icons/instagram.png';
import youtubeFilled from '../../assets/landing-page/icons/youtube.png';
import emailFilled from '../../assets/landing-page/icons/email.png';
import linkedinFilled from '../../assets/landing-page/icons/linkedin.png';
import twitterFilled from '../../assets/landing-page/icons/twitter.png';

// footer
import facebook from '../../assets/landing-page/icons/footer/facebook.svg';
import instagram from '../../assets/landing-page/icons/footer/instagram.svg';
import youtube from '../../assets/landing-page/icons/footer/youtube.svg';
import linkedin from '../../assets/landing-page/icons/footer/linkedin.svg';

const LandingPage: FC = () => {
  const [backgroundColors, setBackgroundColors] = useState([
    '#26D369',
    '#55CCEF',
  ]);
  const [isRounded, setIsRounded] = useState(true);

  const buttonColors = [
    ['#F47FAD', '#7A44E1'],
    ['#FDB757', '#F95DC9'],
    ['#FFAF0D', '#FC6D5F'],
    ['#26D369', '#55CCEF'],
    ['#232323', '#232323'],
  ];

  const changingPhrases = [
    'para suas redes sociais',
    'para sua empresa',
    'que você realmente precisa',
    'para chamar de seu',
    'para tudo',
  ];

  const handleColorChange = (index: number) => {
    setBackgroundColors([buttonColors[index][0], buttonColors[index][1]]);
  };

  return (
    <div id="landing-page">
      <header>
        <div className="container flex-content">
          <img src={connect} alt="Suiteshare Store Logo" />
          <a href="https://app.suitesha.re/connect/login">Entrar</a>
        </div>
      </header>

      <main>
        <section className="create-store">
          <div className="container flex-content column">
            <div className="wrapper-1-1 main-title">
              <h1>
                <strong>Um link</strong>
                <Typed
                  strings={changingPhrases}
                  typeSpeed={60}
                  backSpeed={40}
                  style={{
                    color: '#fd397a',
                    whiteSpace: 'nowrap',
                  }}
                  loop
                />
              </h1>
              <button type="button" className="main-cta">
                <a
                  href="https://app.suitesha.re/connect/signup"
                  title="Cadastre-se"
                >
                  Criar grátis
                </a>
              </button>
            </div>
            <div className="wrapper-1-1 img-wrapper">
              <figure>
                <img className="line-bg" src={line} alt="" />
                <img
                  src={gif}
                  alt="Gif da página principal do Connect sendo utilizado"
                />
              </figure>
            </div>
          </div>
        </section>

        <section className="checkout">
          <div className="container flex-content">
            <div className="content wrapper-1-2">
              <figure>
                <img src={macMockup} alt="Checkout da Store" />
              </figure>
            </div>
            <div className="content wrapper-1-2">
              <div className="section-title">
                <span>1</span>
                <h2>
                  <strong>Gerencie</strong> seus Links
                </h2>
              </div>

              <p>
                Criar um Connect leva segundos. Use nosso editor simples para
                adicionar e gerenciar seu conteúdo sem esforço e dar destaque
                aos seus principais canais.
              </p>
            </div>
          </div>
        </section>

        <section className="customize-preview">
          <div className="container flex-content">
            <div className="content wrapper-1-2">
              <div className="section-title">
                <span>2</span>
                <h2>
                  <strong>Personalize</strong> a aparência
                </h2>
              </div>
              <p>
                Deixe sua página com a cara da sua empresa em poucos cliques.
              </p>
              <div className="customize-buttons-wrapper">
                {buttonColors.map((button, index) => {
                  return (
                    <div
                      className={`color-wrapper ${
                        button[0] === backgroundColors[0] ? 'active' : ''
                      }`}
                      key={button[0] + button[1]}
                    >
                      <button
                        className="color-button"
                        type="button"
                        style={{
                          background: `linear-gradient(140.3deg, ${button[0]} 5.97%, ${button[1]} 91.79%)`,
                        }}
                        onClick={() => handleColorChange(index)}
                      >
                        color
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="switch-wrapper">
                <Switch
                  checked={isRounded}
                  onChange={() => setIsRounded(!isRounded)}
                />
                <p>Bordas arredondadas</p>
              </div>
              <button className="main-cta" type="button">
                <a
                  href="https://app.suitesha.re/connect/signup"
                  title="Cadastre-se"
                >
                  Cadastre-se grátis e veja mais!
                </a>
              </button>
            </div>
            <div className="content wrapper-1-2">
              <div className="connect-preview">
                <img src={mobileMockup} alt="Checkout da Store" />
                <div className="connect-wrapper">
                  <div
                    className="connect-header"
                    style={{
                      borderRadius: '1rem 1rem 0 0',
                      background: `linear-gradient(191.69deg, ${backgroundColors[1]} 5.39%, ${backgroundColors[0]} 100%)`,
                    }}
                  >
                    <div
                      className="logo-wrapper"
                      style={{ borderRadius: isRounded ? '0 0 3rem 0' : '' }}
                    >
                      <p>Logotipo</p>
                    </div>
                  </div>
                  <div
                    className="connect-body"
                    style={{
                      borderRadius: '0 0 1rem 1rem',
                    }}
                  >
                    <div
                      className="gradient-wrapper"
                      style={{
                        borderRadius: isRounded ? '2rem 0 0 0' : '',
                        background: `linear-gradient(150.69deg, ${backgroundColors[0]} 5.39%, ${backgroundColors[1]} 100%)`,
                      }}
                    >
                      <div className="link-wrapper">
                        <button type="button">
                          <a href="$">Atendimento via WhatsApp</a>
                        </button>
                        <button type="button" className="active">
                          <a href="$">Se inscreve no canal ;)</a>
                        </button>
                        <button type="button">
                          <a href="$">Veja o nosso blog</a>
                        </button>
                        <button type="button">
                          <a href="$">Ver catálogo</a>
                        </button>
                      </div>
                      <div className="connect-footer">
                        <img src="" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customize-buttons-wrapper mobile">
                {buttonColors.map((button, index) => {
                  return (
                    <div
                      className={`color-wrapper ${
                        button[0] === backgroundColors[0] ? 'active' : ''
                      }`}
                      key={button[0] + button[1]}
                    >
                      <button
                        className="color-button"
                        type="button"
                        style={{
                          background: `linear-gradient(140.3deg, ${button[0]} 5.97%, ${button[1]} 91.79%)`,
                        }}
                        onClick={() => handleColorChange(index)}
                      >
                        color
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="share">
          <div className="container flex-content">
            <div className="content wrapper-1-2">
              <div className="icons-row">
                <figure>
                  <img className="icon-whats" src={whatsappFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-pin" src={pinterestFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-tiktok" src={tiktokFilled} alt="" />
                </figure>
              </div>
              <div className="icons-row">
                <figure>
                  <img className="icon-face" src={facebookFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-insta" src={instagramFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-yt" src={youtubeFilled} alt="" />
                </figure>
              </div>
              <div className="icons-row">
                <figure>
                  <img className="icon-email" src={emailFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-linkedin" src={linkedinFilled} alt="" />
                </figure>
                <figure>
                  <img className="icon-twitter" src={twitterFilled} alt="" />
                </figure>
              </div>
            </div>
            <div className="content wrapper-1-2">
              <div className="section-title">
                <span>3</span>
                <h2>
                  <strong>Compartilhe</strong> em qualquer lugar
                </h2>
              </div>

              <p>
                Depois de criar o seu Connect e adicionar seus canais,
                compartilhe o link em suas redes sociais e espalhe sua marca.
              </p>
            </div>
          </div>
        </section>

        <section className="store-sections">
          <div className="container">
            <h2>
              Todos os seus canais de vendas e redes sociais em uma única
              página!
            </h2>
          </div>
          <div className="container gif-container">
            <img className="base" src={bananelaInsta} alt="Celular gif" />
            <img
              className="animated-img"
              src={bananelaConnect}
              alt="Celular gif"
            />
          </div>
        </section>

        <section className="start-now">
          <div className="container">
            <a
              href="https://app.suitesha.re/connect/signup"
              title="Começar agora"
            >
              Crie seu
              <strong style={{ marginLeft: '0.3rem', fontWeight: 700 }}>
                Connect
              </strong>
              <FiArrowRight />
            </a>
            <span>Um produto Suiteshare</span>
          </div>
        </section>
      </main>

      <footer>
        <div className="line container">
          <img src={line} alt="Linha de background" />
        </div>
        <div className="container flex-content space-between vertically-centered">
          <div className="social content wrapper-1-3">
            <figure>
              <img src={suitshare} alt="Suiteshare Logo" />
            </figure>
            <div className="social-media row">
              <a
                className="icon"
                href="https://www.facebook.com/suiteshare"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <img src={facebook} alt="Facebook logo" />
              </a>
              <a
                className="icon"
                href="https://www.instagram.com/suiteshare/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <img src={instagram} alt="Instagram logo" />
              </a>
              <a
                className="icon"
                href="https://www.youtube.com/channel/UCjd9M-ab0g9fHOGulwspfFg"
                target="_blank"
                rel="noopener noreferrer"
                title="YouTube"
              >
                <img src={youtube} alt="Youtube logo" />
              </a>
              <a
                className="icon"
                href="https://www.linkedin.com/company/suiteshare"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
              >
                <img src={linkedin} alt="Linkedin logo" />
              </a>
            </div>
          </div>
          <div className="info content wrapper-1-2">
            <div className="content wrapper-1-3">
              <a href="https://suiteshare.com" title="Produtos Suiteshare">
                Produtos Suiteshare
              </a>
              <a
                href="mailto:hello@suiteshare.com"
                title="Enviar um e-mail para hello@suiteshare.com"
              >
                hello@suiteshare.com
              </a>
              <a
                href="https://materiais.suiteshare.com/playbook-vendas-whatsapp?_ga=2.184029731.401498669.1601993540-283891106.1596554696"
                title="Playbook WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Playbook WhatsApp
              </a>
              <a
                href="https://suiteshare.com/blog/"
                title="Blog"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </div>
            <div className="content wrapper-1-3">
              <a
                href="https://suiteshare.com/legal#privacy-policy"
                title="Políticas e privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                Políticas e privacidade
              </a>
              <a
                href="https://suiteshare.com/legal#terms-of-use"
                title="Termos de uso"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos de uso
              </a>
              <a href="https://whts.co/jobs" title="Trabalhe conosco">
                Trabalhe conosco
              </a>
              <span>CNPJ 29.483.159/0001-96</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
