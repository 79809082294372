import styled from 'styled-components';

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: #232323;
`;

export const Image = styled.img`
  width: 200px;
  height: 150px;
  object-fit: contain;
`;
