import React from 'react';

// Styles
import { Footer } from './styles';

// Images
import suiteshare from '../../assets/suiteshare.png';

const AppFooter: React.FC = () => (
  <Footer>
    <a href="https://suiteshare.com/">
      <img src={suiteshare} alt="Suiteshare Logo" />
    </a>
  </Footer>
);

export default AppFooter;
