import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

import { ICompanyData, IConnects } from '../../Types';

import Services from '../../services';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import {
  ConnectContainer,
  ConnectBody,
  LinkWrapper,
  LinkScroll,
} from './styles';

interface IUrlParams {
  companyName: string;
}

const Connect: FC = () => {
  const { companyName } = useParams<IUrlParams>();

  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState<ICompanyData | undefined>();
  const [connectData, setConnectData] = useState<IConnects | undefined>();
  const [matchColors, setMatchColors] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (companyName) {
      Services.getCompanyData(companyName)
        .then((companydata) => {
          setCompanyData(companydata);
          Services.getConnectData(companydata.id).then((connectdata) => {
            setConnectData(connectdata);
            if (connectdata.primary_color === connectdata.header_color) {
              setMatchColors(true);
            }
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [companyName]);

  const logo = connectData?.company_logo
    ? connectData?.company_logo
    : companyData?.logo;

  return (
    <ConnectContainer>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Header
            background={[
              connectData?.primary_color,
              connectData?.secondary_color,
            ]}
            isArabesque={connectData?.rounded_header}
            headerColor={connectData?.header_color}
            matchColors={matchColors}
            img={`https://suiteshare-assets.s3-sa-east-1.amazonaws.com/${logo}`}
            company={companyData}
          />
          <ConnectBody style={{ height: `${matchColors ? '70%' : ''}` }}>
            <LinkWrapper
              background={[
                connectData?.primary_color,
                connectData?.secondary_color,
              ]}
              isArabesque={connectData?.rounded_header}
              style={{ padding: `${matchColors ? '0 2rem' : ''}` }}
            >
              <LinkScroll>
                {connectData ? (
                  connectData.links
                    .sort((a, b) => a.position - b.position)
                    .map((link) => {
                      return link.enabled ? (
                        <Button key={link.id} config={link} />
                      ) : (
                        <></>
                      );
                    })
                ) : (
                  <div />
                )}
              </LinkScroll>
              <Footer />
            </LinkWrapper>
          </ConnectBody>
        </>
      )}
    </ConnectContainer>
  );
};

export default Connect;
