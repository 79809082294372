import { message } from 'antd';

import api from './api';
import { ICompanyData, IConnects } from '../Types';

export default class Services {
  static readonly getCompanyData = (
    socialUrl: string,
  ): Promise<ICompanyData> => {
    return api
      .get(`/companies/${socialUrl}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => message.error(error.message));
  };

  static readonly getConnectData = (companyId: string): Promise<IConnects> => {
    return api
      .get(`/company/${companyId}/connects`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => message.error(error.message));
  };

  static readonly putConnectData = (
    companyId: string,
    connectData: IConnects,
  ): Promise<IConnects> => {
    return api
      .put(`/company/${companyId}/connects`, connectData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => message.error(error.message));
  };
}
