import styled from 'styled-components';

export const Container = styled.div``;

export const AppContainer = styled.div`
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (min-width: 768px) {
    padding: 20px 0;
  }
`;
