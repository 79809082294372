import styled from 'styled-components';
import { fade } from '../../styles/animations';

interface IColorProps {
  background: (string | undefined)[];
}

interface IBoxProps {
  isArabesque?: boolean;
  headerColor?: string;
}

export const HeaderContainer = styled.div<IColorProps>`
  width: 100%;
  height: 40%;
  background: linear-gradient(
    141.65deg,
    ${(props) => props.background[1] || `#fff`} 1.62%,
    ${(props) => props.background[0] || `#fff`} 158.12%
  );
  display: flex;
  flex-direction: column;
  animation: ${fade} 3s;
`;

export const HeaderContent = styled.div<IBoxProps>`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => (props.isArabesque && `0 0 2rem 0`) || `0`};
  background: ${(props) => (props.headerColor && props.headerColor) || `#fff`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  figure {
    width: 70%;
    margin: 0;
    img {
      width: 100%;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0 0;
`;
