import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin: 0 0.3rem;
`;
export const Anchor = styled.a`
  width: 100%;
  display: inline-block;
`;
export const Icon = styled.img`
  width: 1.3rem;
  height: auto;
  object-fit: scale-down;
`;
