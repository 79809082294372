import React, { useState, useEffect } from 'react';

// Styles
import { IconWrapper, Anchor, Icon } from './styles';

// Icons
import Facebook from '../../assets/icons/facebook.png';
import Instagram from '../../assets/icons/instagram.png';
import Linkedin from '../../assets/icons/linkedin.png';
import Twitter from '../../assets/icons/twitter.png';
import Youtube from '../../assets/icons/youtube.png';

interface IProps {
  url: string;
  youtube?: boolean;
  twitter?: boolean;
  facebook?: boolean;
  instagram?: boolean;
  linkedin?: boolean;
}

const SocialMediaIcon: React.FC<IProps> = (props) => {
  const { url, youtube, twitter, facebook, instagram, linkedin } = props;

  const [selectedMedia, setSelectedMedia] = useState('');

  useEffect(() => {
    switch (true) {
      case youtube:
        setSelectedMedia(Youtube);
        break;
      case twitter:
        setSelectedMedia(Twitter);
        break;
      case facebook:
        setSelectedMedia(Facebook);
        break;
      case instagram:
        setSelectedMedia(Instagram);
        break;
      case linkedin:
        setSelectedMedia(Linkedin);
        break;
      default:
        break;
    }
  }, [youtube, twitter, facebook, instagram, linkedin]);

  return (
    <IconWrapper>
      <Anchor href={url} target="_blank" rel="noopener noreferrer">
        <Icon src={selectedMedia} />
      </Anchor>
    </IconWrapper>
  );
};

export default SocialMediaIcon;
