import React, { FC, useEffect, useState } from 'react';

import { IConnectLinks } from '../../Types';

import { ButtonBody } from './styles';

interface IProps {
  config: IConnectLinks;
}

const Button: FC<IProps> = (props) => {
  const { config } = props;

  const [link, setLink] = useState(config.url);

  useEffect(() => {
    if (config) {
      const currLink = config.url.split('//');

      if (currLink[0] !== 'https:' && currLink[0] !== 'http:') {
        currLink.unshift('https:');
      }
      if (currLink[0] === 'http:') {
        currLink[0] = 'https:';
      }

      setLink(currLink.join('//'));
    }
  }, [config]);

  return (
    <ButtonBody
      type="button"
      isHighlighted={config.highlight}
      disabled={!config.enabled}
      background={config.button_color}
      color={config.font_color}
      title={config.title}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        {config.title}
      </a>
    </ButtonBody>
  );
};

export default Button;
