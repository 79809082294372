import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Connect from '../pages/Connect';
import LandingPage from '../pages/LandingPage';

import { AppContainer } from './styles';
import { Container } from '../styles/default';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <AppContainer>
        <Container>
          <Route path="/:companyName">
            <Connect />
          </Route>
        </Container>
      </AppContainer>
    </Switch>
  );
};

export default Routes;
