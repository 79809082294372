import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow: hidden;
  @media (min-width: 768px) {
    border-radius: 20px;
    max-width: 375px;
    box-shadow: 3px 3px 4px #cecece;
  }
`;

export const Content = styled.div``;
